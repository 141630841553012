<template>
  <div class="benefits rounded primary-address-form">
    <div class="col-md-12 py-4 m-auto">
      <!-- <b-row class="border-bottom mx-3"> -->
      <!-- <b-col cols="6" md="3" xl="2" class="text-left text-md-right">
        <b-button
          pill
          variant="secondary"
          class="w-100"
          :to="{ name: 'PrimaryContact' }"
          replace
          >{{ $t("global.button.cancel") }}
        </b-button>
      </b-col>
      <b-col cols="6" md="3" xl="2" class="text-right text-md-right">
        <b-button
          :disabled="!mobileValid"
          pill
          variant="primary"
          class="w-100"
          @click="updateUser()"
        >
          {{ $t("global.button.save") }}
        </b-button>
      </b-col> -->
      <!-- </b-row> -->
      <div
        class="border-bottom d-flex justify-content-between align-items-center pb-2 mb-5"
      >
        <div>
          <h4 class="section-title">
            {{ $t("dashboard.label.location") }}
          </h4>
        </div>
      </div>
      <b-card class="mx-auto my-3">
        <the-address-form
          v-if="
            $store.getters.user.Contact.Country &&
            $store.getters.user.Roles.includes('Seller')
          "
          :countryLocked="$store.getters.user.Contact.Country"
          @addressChange="handleAddress"
        />
        <the-address-form v-else @addressChange="handleAddress" />
        <div class="row">
          <b-col cols="6" class="pr-2">
            <b-button
              pill
              variant="secondary"
              class="w-100"
              :to="{ name: 'PrimaryContact' }"
              replace
              >{{ $t("global.button.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6" class="pl-2">
            <b-button pill variant="primary" class="w-100" @click="updateUser()">
              {{ $t("global.button.save") }}
            </b-button>
          </b-col>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import TheAddressForm from "../../global/TheAddressForm.vue";

export default {
  components: {
    TheAddressForm,
  },
  data() {
    return {
      streetAddress1: this.$store.getters.user.Contact.AddressLine,
      streetAddress2: this.$store.getters.user.Contact.AddressLine2,
      city: this.$store.getters.user.Contact.City,
      postalCode: this.$store.getters.user.Contact.ZipCode,
      country: this.$store.getters.user.Contact.Country.Name,
      countryCode: this.$store.getters.user.Contact.Country.Code,
      province: this.$store.getters.user.Contact.Province,
      address_list: null,

      errorMassage: null,
    };
  },
  methods: {
    handleAddress(address) {
      this.streetAddress1 = address.streetAddress;
      this.streetAddress2 = address.apt;
      (this.city = address.city),
        (this.postalCode = address.postal),
        (this.country = address.country),
        (this.province = address.region);
    },
    async updateUser() {
      //Changes the user info in the DB

      let countryCode;
      this.$store.getters.countries.forEach((element) => {
        if (this.country === element.Name) countryCode = element.Code;
      });
      this.$store.dispatch("updateUser", {
        ID: this.$store.state.user.user.UserId,

        AddressLine: this.streetAddress1,
        AddressLine2: this.streetAddress2,
        ZipCode: this.postalCode,
        City: this.city,
        Province: this.province,
        LanguageCode: this.$i18n.locale,
        CountryCode: countryCode,
      });
      this.$router.push({ name: "PrimaryContact" });
    },
    onComplete(v) {
      this.verificationCode = v;
      this.mobileValidate();
    },
    handleMobile(mobile, isValid, c, countryCode) {
      this.mobile = mobile;
      this.mobileValid = isValid;
      this.mobileCountryCode = countryCode;
      c;
    },
  },
};
</script>

<style lang="scss">
.input-tel__input {
  border-radius: 0px !important;
  border-right: 0px !important;
}

.country-selector__input {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.select-country-container {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}
.primary-address-form {
  .card {
    width: 400px;
  }
  .section-title {
    font-weight: 600;
    font-size: 20px;
    font-family: "Inter", sans-serif !important;
    color: #111111;
    margin-bottom: 0;
  }
  .card-body {
    padding: 24px;
    .card-body {
      padding: 0;
      margin-bottom: 24px;
    }
  }
  input {
    height: 48px;
    border-color: #d0d0d0 !important;
    box-shadow: none !important;
  }
  .item {
    margin-bottom: 24px;
  }
  .input-tel__input {
    border-left: 0 !important;
    &::placeholder {
      color: #acacac !important;
      font-size: 12px;
      font-family: "Inter", sans-serif !important;
    }
  }
  .btn:not(.btn-link) {
    height: 48px;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-secondary {
      border: 1px solid $light-blue;
      color: $light-blue;
      background: none;
    }
    &:hover {
      background: #00559d !important;
      color: white !important;
    }
  }
}
</style>
